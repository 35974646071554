.tabel-wrapper{
  display: flex;
  justify-content: center;
  padding-bottom:50px;
  width:100%;
  align-items: center;
  flex-direction: column;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  direction: ltr;

}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width:60%;
  border: 1px solid #ddd;
  max-width: 1000px;
}

th, td {
  text-align: left;
  padding: 8px;
}

caption {
  margin-bottom: 24px;
}

tr:nth-child(even){background-color: #f2f2f2}

@media screen and (max-width: 900px) {
  .tabel-wrapper{
    width:500px;
  }

  table {
    width:100%;
  }
}

@media screen and (max-width: 500px) {
  .tabel-wrapper{
    width:325px;
  }
}

@media screen and (max-width: 350px) {
  .tabel-wrapper {
    width: 225px;
  }
}

  @media screen and (max-width: 250px) {
    .tabel-wrapper {
      width: 125px;
    }
  }

