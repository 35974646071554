.read-more-button {
  background-color: #29316e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 150px;
  position: absolute;
  bottom: -50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  p {
    padding: 10px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
}

a {
  text-decoration: none;
}

.read-more-button:hover {
  cursor: pointer;
  background-color: #6BC4DE;
}

@media only screen and (max-width: 1200px) {
  .read-more-button {
    position: static;
  }
}

@media only screen and (max-width: 200px) {
  .read-more-button {
    width: 100px;

    p {
      font-size: 10px;
      padding: 5px;
    }
  }
}
