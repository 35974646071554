.landing-wrapper {
  width: 100%;
  padding: 0 0 5vw 0;
  color: white;
}

.landing-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.landing-image {
  width: 80%;
  max-width: 1100px;
  height: 100%;
  padding-bottom: 20px;
  z-index: 0;
  margin-top: 5vw;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;



}

.landing-image-text{
  position: absolute;
  left:50%;
  width: 100%;
  height: 100%;
  top:0;
  transform: translate(-50%,0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; font-family: "Poppins", sans-serif;

  h1{
    text-align: center;
    max-width: 700px;
      font-family: "Poppins", sans-serif;
  }
  h3{
    color:white;
    text-align: center;
    max-width: 700px;
    width: 80%;
  }

}

.landing-image-picture {
  width: 100%;
  height: auto;
}

.landing-image-shape-1 {
  position: absolute;
  bottom: 15%;
  right: 20%;
  width: 5%;
}

.landing-image-shape-2 {
  position: absolute;
  top: 15%;
  left: 20%;
  width: 5%;

}

.landing-background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.colored-rectangle {
  width: 70%;
  height: auto;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.striped-square {
  width: 60%;
  height: auto;
  position: absolute;
  z-index: -2;
  top: 2vw;
  left: 5vw;
}

.landing-image-mobile {
  display: none;
}

.landing-page-context {
  display: grid;
  grid-template-rows: 0.1fr 0.1fr 0.1fr;
  grid-row-gap: 2em;
  justify-items: center;
  align-items: center;
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  padding: 20% 0 0 0;
}

.landing-title {
  display: inline-block;
  width: 100%;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  font-size: 26px;
  letter-spacing: 0.2em;
  border-bottom: white 2px solid;
  border-top: white 2px solid;
  animation: border-width 1s linear,
  border-padding 0.5s 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.landing-title > h2 {
  color:white;
  margin-top: 0;
}

.landing-text {
  font-size: 26px;
  letter-spacing: 0.1em;
  animation: reveal 1.5s 1.2s ease-in forwards;
  text-align: center;
  opacity: 0;
  color:white !important;
}

.landing-button {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #FFFFFF;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.2s;
  user-select: none;
  cursor: pointer;
  opacity: 0;
  font-size: 26px;
  animation: reveal 1.5s 1.7s ease-in forwards;
}

.landing-button:hover {
  background-color: #29316e;
  border-color: #29316e;
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes border-width {
  0% {
    width: 0;
    opacity: 0;
  }

  20% {
    width: 20%;
    opacity: 0.2;
  }

  40% {
    width: 40%;
    opacity: 0.4;
  }

  60% {
    width: 60%;
    opacity: 0.6;
  }

  80% {
    width: 80%;
    opacity: 0.8;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes border-padding {
  0% {
    padding-bottom: 0;
    padding-top: 0;
  }
  40% {
    padding-bottom: 0.1em;
    padding-top: 0.1em;
  }

  60% {
    padding-bottom: 0.2em;
    padding-top: 0.2em;

  }

  80% {
    padding-bottom: 0.4em;
    padding-top: 0.4em;
  }

  100% {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
}

@media only screen and (max-width: 800px) {
  .landing-wrapper {
    position: static;
  }

  .landing-image {
    h1, h3 {
      text-align: center;

    }

    h1 {
      font-size: 30px;
      max-width: 400px;
    }
  }
}

@media only screen and (max-width: 600px) {

  .landing-image {

    h1 {
      font-size: 30px;
    }

    h3{
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 400px) {

  .landing-image {

    h1 {
      font-size: 24px;
    }

    h3{
      font-size: 10px;
    }
  }
}

