
.team-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width:100%;
  flex-wrap: wrap;
  margin-top: 20px;
}
 .team-center > h2{
  margin-bottom: 1em;
  color: #0B298D;
   //font-size: 26px  ;
}

 .team-text{
   max-width: 500px;
   width: 90%;
 }

.team-card{
  width:33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  padding: 5em 0 5em 0;
}

.team-image{
  border-radius: 50%;
  width:250px;
  height:250px;
  justify-self: center;
  background-size: cover;
  background-position: center center;
}

.team-image-info{
  margin-top: 1em;
  //font-weight: bold;
}

.team-image-name{
  font-size: 24px;
  //font-weight: bold;
}

.team-image-info p{
  font-size: 20px;
}

.team-image-info span{
  border: solid 1px #6BC4DE;
  width:40%;
  margin-top: 20px
}

.team-image-text{
  height:100%;
  margin-top: 1em;
  padding: 0 5em 0 5em;
  line-height: 25px;
}

.team-social-media-wrapper{
  width:100px;
}

.team-social-media {
  display: flex;
  width:100%;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 2em;

}
.team-social-media > a {
  font-size:32px;
  color:#6BC4DE;
  display: inline-block;
  padding: 0 0.3em 0 0.3em;
}

.team-social-media > a:hover {
  cursor: pointer;
}

.team-center{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

@media only screen and (max-width: 1500px) {
  .team-card{
    width:45%;
  }

  }

@media only screen and (max-width: 1200px) {
  .team-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .team-card{
    width:50%;
  }

  .team-image-text{
    padding: 0 0 0 0;
  }

}

@media only screen and (max-width: 600px) {
  .team-card{
    width:90%;
    padding: 50px 0;
  }
  .team-image-text{
    padding: 0 2em 0 2em;
  }
  .team-image{
    width:200px;
    height:200px;
  }
  .team-center > h2{
    font-size: 26px;
  }

   .team-center{
    padding : 0;
  }
}

@media only screen and (max-width: 300px) {
  .team-card{
    width:90%;
  }

  .team-image{
    width:150px;
    height:150px;
  }
  .team-image-text{
    padding: 0;
  }
}
