.section-wrapper {
  width: 100%;
  display: flex;
  padding: 5em 0 5em 0;
}

.section-body {
  width: 100%;
  position: relative;
}

.section-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: absolute;
  width: 100%;
  top: 0;
  align-items: center;
  height: 100%;
}

.section-background {
  width: 50%;
}

.section-image {
  width: 80%;
}

.section-logo {
  width: 150px;
  align-self: start;
  justify-self: start;
}

.section-text {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 3em;

}

.section-text > p {
  margin-top: 2em;
  line-height: 1.2em;
  color: white;
}

.shapes-wrapper {
  position: relative;
  height: auto;

}

.half-square {
  width: 5%;
  position: absolute;
  //Was 2.5
  right: 25%;
  bottom: 5%;
}

.square {
  position: absolute;
  top: -11%;
  right: 20%;
  width: 10%;
}

.section-button:hover {
  cursor: pointer;
}

.section-button {
  background-color: #29316e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  height: 50px;
  width: 150px;

  p {
    padding: 10px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
}

.section-title {
  color: #0B298D;
  margin-bottom: 1em;
  font-size: 34px;
}

.mobile-section {
  display: none;
}

.section-image-mobile {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .section-title {
    font-size: 2vw;
  }
}


@media only screen and (max-width: 1200px) {
  .section-wrapper {
    flex-direction: column;
    display: flex;
    padding: 0 0 5em 0;
  }

  .section-content {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section-background {
    display: none;
  }

  .section-logo {
    display: none;
  }

  .section-text {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 60%;
    text-align: center;
    color: #29316e;
  }

  .section-title {
    margin-bottom: 0;
    font-size: 34px;
  }
  .section-text > p {
    margin-top: 1em;
    margin-bottom: 2em;
    color: #484848;
    text-align: justify;

  }

  .section-button {
    margin: 0 0 2em 0;
  }

  .square {
    top: -8%;
  }

  .half-square {
    bottom: 10%;
    width: 10%;
  }

  .pc-section {
    display: none;
  }

  .mobile-section {
    display: flex;
  }

  .section-image {
    display: none;
  }

  .section-image-mobile {
    display: flex;
    width: 100vw
  }

}

@media only screen and (max-width: 800px) {
  .section-content {
    align-items: center;
  }

  .section-title {
    font-size: 26px;
  }

  .section-text {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .section-text {
    width: 90%;
    padding: 32px 0;
  }

  .section-title {
    font-size: 22px;
  }

  .section-text > p {
    font-size: 16px;
  }
}
