@import url("https://fonts.googleapis.com/css?family=Francois+One");
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import "general/general_button";
@import "general/read-more-button";
@import "general/navigation";
@import "general/footer";
@import "hamburgers/hamburgers";
@import "page/pageStyles";
@import "about/team.scss";
@import "about/adress.scss";
@import "about/contact-form.scss";
@import "general/404.scss";
@import "general/landing.scss";
@import "general/grid-item.scss";
@import "homePage/workCards.scss";
@import "homePage/events.scss";
@import "homePage/phares.scss";
@import "opdrachtgever/tabel.scss";
@import "page/contact_page.scss";
@import '../../node_modules/aos/dist/aos.css';
@import "page/read-more.scss";


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", "sans-serif" !important;
}

*:before,
*:after {
    box-sizing: border-box;
}

.App-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

body {
    background-color: #ffffff;
    overflow-x: hidden;
    box-sizing: border-box;
    word-break: break-word;
}

#body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1 {
    color: #0B298D;
    margin-top: 20px;
    font-size: 60px;
    font-family: "Poppins SemiBold", "sans-serif" !important;

    @media only screen and (max-width: 400px) {
        font-size: 30px;
    }

    @media only screen and (max-width: 300px) {
        font-size: 26px;
    }
    @media only screen and (max-width: 200px) {
        font-size: 22px;
    }
}

h2 {
    color: #0B298D;
    margin-top: 20px;
    font-size: 30px;
    font-family: "Open Sans", "sans-serif" !important;
    //@media only screen and (max-width: 800px) {
    //  font-size: 26px;
    //}
    @media only screen and (max-width: 400px) {
        font-size: 22px;
    }

    @media only screen and (max-width: 300px) {
        font-size: 18px;
    }
    @media only screen and (max-width: 200px) {
        font-size: 16px;
    }
}

h3 {
    color: #0B298D;
    margin-top: 20px;
    font-size: 26px;
    font-family: "Open Sans", "sans-serif" !important;
    @media only screen and (max-width: 1200px) {
        font-size: 24px;
    }
    @media only screen and (max-width: 400px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 300px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 200px) {
        font-size: 14px;
    }
}

h4 {
    font-weight:100;
    font-size: 18px;
    font-family: "Open Sans", "sans-serif" !important;
    @media only screen and (max-width: 1200px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 400px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 300px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 200px) {
        font-size: 14px;
    }
}

p {
    font-size: 16px !important;
    line-height: 25px;
    font-family: "Open Sans", "sans-serif" !important;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media only screen and (max-width: 400px) {
        font-size: 14px;
        line-height: 20px;
    }

    @media only screen and (max-width: 200px) {
        font-size: 12px;
        line-height: 18px;
    }
}

//@supports (-ms-ime-align:auto) {

.banner-amar {
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.banner-item-amar {
    height: 15vw;
    flex-basis: 20%;
    -ms-flex: auto;
    width: 20vw;
    position: relative;
    padding: 10px;
    box-sizing: border-box;

    position: relative;
}

.banner-item-amar > img {
    width: 50%;
    height: 18vw;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
}


.banner-wrapper {
    width: 100%;
    height: auto;
    padding-top: 50px;
    position: relative;
    background: rgb(37, 37, 134);
    background: -o-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgb(37, 37, 134)), to(rgb(107, 196, 222)));
    background: linear-gradient(to right, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53bcb3', endColorstr='#3f92c2', GradientType=1);
    display: flex;
    align-items: center;
}

.banner-wrapper-wrapper {
    width: 90%;
    margin: auto;
}

.banner-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.banner {

    //width: 100%;
    //max-width: 2000px;
    //height: 100%;
    //padding: 50px 0;
    //display: -webkit-box;
    //display: -ms-flexbox;
    //display: flex;
    //margin: auto;
    //-webkit-box-align: center;
    //-ms-flex-align: center;
    //align-items: center;
    //-webkit-box-pack: center;
    //-ms-flex-pack: center;
    //justify-content: center;
    //-webkit-box-orient: horizontal;
    //-webkit-box-direction: normal;
    //-ms-flex-direction: row;
    //flex-direction: row;
    //-ms-flex-wrap: wrap;
    //flex-wrap: wrap;
    display: -ms-grid;
    display: grid;
    grid-column-gap: 150px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.banner-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.banner-item > img {
    height: 100%;
    width: 150px;
    padding: 0 0.5em;
    margin: 1em 0;
}

@media only screen and (min-width: 1500px) {

    #belastingdienst {
        height: 100% !important;
        width: 15vw !important;

    }

    #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
        height: 100% !important;
        width: 20vw !important;

    }

}

@media only screen and (min-width: 1200px) {

    #belastingdienst {
        height: 100% !important;
        width: 10vw !important;


    }

    #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
        height: 100% !important;
        width: 15vw !important;


    }

}

@media only screen and (max-width: 1200px) {


    .banner {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-column-gap: 150px;

    }



}

@media only screen and (max-width: 950px) {

    .banner {
        grid-template-columns: repeat(auto-fill, minmax(650px, 1fr));
        grid-column-gap: 800px;

    }


    #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
        height: 100% !important;
        width: 20vw !important;

    }

    .banner-item-amar {
        width: 33vw;
        flex-basis: 33%;
    }

    .banner-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        align-self: center;
        justify-self: center;
    }

    .banner-item > img {
        width: 90%;
    }


    .footer {
        font-size: 10px;
    }
    //#belastingdienst {
    //  width: 100% !important;
    //
    //}
    //
    //#ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
    //  width: 100% !important;
    //}
}


@media only screen and (max-width: 950px) {

    .banner {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-row-gap: 40px;
        grid-column-gap: 10px;

    }


}

//}


//.banner-wrapper {
//  width: 100%;
//  height: 100%;
//  padding-top: 50px;
//  position: relative;
//  background: rgb(37, 37, 134);
//  background: -o-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
//  background: -webkit-gradient(linear, left top, right top, from(rgb(37, 37, 134)), to(rgb(107, 196, 222)));
//  background: linear-gradient(to right, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53bcb3', endColorstr='#3f92c2', GradientType=1);
//  display: flex;
//  align-items: center;
//}
//
//.banner-wrapper-wrapper {
//  width: 90%;
//  margin: auto;
//
//}
//
//.banner-wrapper:after {
//  content: "";
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  left: 0;
//  top: 0;
//  background-color: rgba(0, 0, 0, 0.2);
//
//}
//
//.banner {
//  width: 100%;
//  max-width: 2000px;
//  height: 100%;
//  padding: 50px 0;
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: flex;
//  margin: auto;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//  -webkit-box-pack: center;
//  -ms-flex-pack: center;
//  justify-content: center;
//  -webkit-box-orient: horizontal;
//  -webkit-box-direction: normal;
//  -ms-flex-direction: row;
//  flex-direction: row;
//  -ms-flex-wrap: wrap;
//  flex-wrap: wrap;
//}
//
//.banner-item {
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: flex;
//  width: 100%;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//  -webkit-box-pack: center;
//  -ms-flex-pack: center;
//  justify-content: center;
//}
//
//.banner-item > img {
//  height: 100%;
//  width: 150px;
//  padding: 0 0.5em;
//  margin: 1em 0;
//}
//
//@media only screen and (min-width: 1500px) {
//
//  #belastingdienst {
//    height: 100% !important;
//    width: 200px !important;
//  }
//
//  #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
//    height: 100% !important;
//    width: 300px !important;
//  }
//
//}
//
//@media only screen and (max-width: 1500px) {
//
//  #belastingdienst {
//    height: 100% !important;
//    width: 200px !important;
//  }
//
//  #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
//    height: 100% !important;
//    width: 250px !important;
//  }
//
//}
//
//@media only screen and (max-width: 1200px) {
//
//  #belastingdienst {
//    height: 100% !important;
//    width: 200px !important;
//  }
//
//  #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
//    height: 100% !important;
//    width: 200px !important;
//  }
//
//}
//
//@media only screen and (max-width: 950px) {
//
//  .banner-item {
//    display: -webkit-box;
//    display: -ms-flexbox;
//    display: flex;
//    width: 33%;
//    -webkit-box-align: center;
//    -ms-flex-align: center;
//    align-items: center;
//    -webkit-box-pack: center;
//    -ms-flex-pack: center;
//    justify-content: center;
//  }
//
//  .banner-item > img {
//    width: 70%;
//  }
//
//
//  .footer {
//    font-size: 10px;
//  }
//  #belastingdienst {
//    width: 100% !important;
//
//  }
//
//  #ministerie-veiligheid, #ministerie-eco, #ministerie-rijkswaterstaat, #stolt-nielsen, #keylane, #vattenfal {
//    width: 100% !important;
//  }
//}

/*custom style for buttom*/
.button {
    display: inline-block;
    padding: 0.5em 1.35em;
    background-color: #29316e;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.25em;
    box-sizing: border-box;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    font-size: 20px;
}

.button:hover {
    background-color: rgba(41, 83, 110, 0.8);
    cursor: pointer;
}
