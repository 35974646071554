@import "contact_form";

.contact-form-page {
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contact-title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    margin-bottom: 25px;
    padding: 30px;
    margin-top: 40px;

    min-height: 20em;
    width: 100%;

    //TODO: Clean this up
    background-image: url("../../images/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1200px) {
      flex-direction: column-reverse;
    }

    .title-subtext {
      margin: 20px 25px 20px 0;

      p {
        color: white;
        padding: 1.5em;
        text-align: justify;
      }

      @media screen and (max-width: 1200px) {
        margin: auto;
      }
    }

    .title-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid white;

      @media screen and (max-width: 1200px) {
        border: none;
        justify-content: flex-start;
      }

      .title {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        //flex-direction: row;
        padding: 20px 25px 20px 25px;

        .title-image {
          width: 120px;
          height: auto;
        }

        @media screen and (max-width: 1200px) {
          margin: 0;
          height: auto;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .title-image {
            width: 70px;
          }
        }

        @media screen and (max-width: 600px) {
          margin: auto;
          padding:0;
          height: auto;
          flex-direction: column;
          align-items: center;

          .title-image {
            width: 100px;
            height:auto;
            margin-bottom: 20px;
          }
        }

        .title-text {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: auto;

          p {
            display: inline-block;
            font-size: 30px;
            line-height: 35px;
            color: white;
          }
        }

        @media screen and (max-width: 600px) {
          width:40%;
        }

      }
    }
  }
}


