.footer-container{
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgb(37, 37, 134);
  background: -o-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgb(37, 37, 134)), to(rgb(107, 196, 222)));
  background: linear-gradient(to right, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53bcb3', endColorstr='#3f92c2', GradientType=1);
  color: white !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-container  h5{
  color:white;
  font-size: 20px;
}

.footer-body{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 5;
  -ms-flex: 5;
  flex: 5;
  padding: 50px 16px 16px 16px;
  width: 80%;
  max-width: 1600px;
  margin:auto;
}

.footer-information{
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  justify-items: center;
  grid-row-gap: 20px;


}

.footer-information-links{
  width:auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  justify-items: center;
  text-align: center;
  padding: 0 20px;
}

.footer-information-links > *:nth-child(1){
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.footer-contact{
  width:100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: 64px;


}

.footer-map-container{
  width:500px;
}

.footer-map{
  width:100%;
  height: 250px;
}

.footer-newsletter{
  -webkit-box-flex:1;
  width:100%;
  max-width: 400px;
  -ms-flex:1;
  flex:1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 50px;
}

.footer-newsletter > input, select, textarea{
  background-color: white;
  border: none;
  border-radius: 0;
  margin: 10px 0;
  text-indent: 10px;
  width:300px;
}

.footer-newsletter > input::-webkit-input-placeholder{
  color: black;
}

.footer-newsletter > input::-moz-placeholder{
  color: black;
}

.footer-newsletter > input:-ms-input-placeholder{
  color: black;
}

.footer-newsletter > input::-ms-input-placeholder{
  color: black;
}

.footer-newsletter > input::placeholder{
  color: black;
}

.footer-newsletter >select{
  color: black;
}

.footer-newsletter > textarea:focus, input:focus {
  color: black;
}

.footer-end {

  background-color: rgba(0,0,0,0.2);
  width: 100%;
  height:100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 36px 0 36px;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  justify-items: flex-end;
}

.footer-end > p {
  color:white;
  font-size: 16px;
  margin-top: auto;
  padding: 20px 0 20px 0;
}

.footer-end  a {
  font-weight: bold;
  color:inherit;
  text-decoration: none;
}

.subscription-button{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  padding:1em 1.5em 1em 1.5em;
  background-color: #6BC4DE;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width:300px;
  margin: 30px auto;
}

.subscription-button:hover{
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .footer-container{
    height: 100%;
  }

  .footer-body{
    width:100%;
    max-width: 100%;
  }

  .footer-container  h5{
    margin-top: 12px;
  }

  .footer-contact{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
  }

  .footer-map-container{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width:100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .footer-map{
    width:100%;
    height: 300px;
  }

  .footer-newsletter{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width:100%;
    padding: 32px;
  }


}
@media only screen and (max-width: 800px) {
  .footer-container{
    height: 100%;
  }

  .footer-body{
    width:100%;
    max-width: 100%;
  }

  .footer-container  h5{
    margin-top: 12px;
  }

  .footer-contact{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-map-container{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width:100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 30px;
  }

  .footer-map{
    width:50%;
    min-width: 200px;
    height: 300px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-newsletter{
    margin:auto;
    padding:0;
  }

  .footer-information{
    -ms-grid-rows: 1fr -webkit-min-content;
    -ms-grid-rows: 1fr min-content;
    grid-template-rows: 1fr -webkit-min-content;
    grid-template-rows: 1fr min-content;

  }

  .footer-information-links{
    margin-top: 20px;
  }

}

@media only screen and (max-width: 600px) {
  .footer-container  h5{
    text-align: center;
  }

  .footer-information{
    justify-items: center;
  }

  .footer-information-links{
    width:100%;
    justify-items: center;
  }

  .footer-map {
    width: 100%;
  }

  .footer-newsletter {
    margin:auto;
    padding: 0;
  }

  .footer-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

  }
  .footer-newsletter > input, select, textarea{
    width:80%;
  }

  .subscription-button{
    width:80%;
  }

  .footer-end {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

  }
  .footer-end > p {
    text-align: center;
    padding: 20px 0 20px 0;
  }

  .footer-end  a {
    font-weight: bold;
    color:inherit;
    text-decoration: none;
  }

  .footer-body {
    padding-top:10px;
  }
}
