
.work-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10vh 0 20vh 0;
  justify-content: center;
  align-items: center;
}

.card-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.work-card {
  flex-direction: column;
  position: relative;
  width: 30%;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.work-box {
  position: relative;
  display: flex;
  width: 150px;
  height: 150px;
  margin-top: 10px;
  background-color: #6BC4DE;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}

.work-text {
  width: 80%;
  padding: 1em 0 1em 0;
  text-align: justify;
}

.bottom {
  position: absolute !important;
  bottom: 0;
}

@media only screen and (max-width: 1200px) {

  .work-wrapper {
    flex-direction: column;
    overflow: hidden;
  }

  .work-card {
    width: 80%;
    margin: 32px 0 64px 0;
  }

  .work-box {
    width: 150px;
    height: 150px;
  }

  .work-text {
    width: 500px;
    min-height: 100px;
  }
}

@media only screen and (max-width: 600px) {

  .work-wrapper {
    padding: 5vh 0 5vh 0;
  }

  .work-card {
    width: 90%;
  }

  .work-text {
    width: 100%;
  }

  .work-box {
    width: 100px;
    height: 100px;
  }

  .work-box > img {
    width: 50%;
    height: auto;
  }
}

@media only screen and (max-width: 300px) {

  .work-box {
    width: 50px;
    height: 50px;
  }
}




