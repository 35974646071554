.grid-item{
  display: flex;
  flex-direction: row;
  color: #CACACA;
  align-items: flex-start;
  height: auto;
  width:auto;

}

.grid-image {
  padding-right: 10px;
  display: flex;
}

.grid-image svg{
  width: 15px;
  color: #6BC4DE;
}

.grid-text {
  display: flex;
  width:100%;
}

.grid-text > a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  display:inline-block;

}

.grid-text > a:hover {
  color:white;
}


@media screen and (max-width: 600px) {
  .grid-text {
    text-align: center;
    word-wrap: break-word

  }
}

@media screen and (max-width: 372px) {
  #arrow-position{
    align-self: flex-start;
    margin-top:4px;
    padding-right: 5px;
  }
}
