$blue_text_color: #0c298d;
$grey_text_color: #484848;

@import "genericPage";
@import "pageItem";

p {
  font-family: "Poppins", sans-serif !important;
  color: $grey_text_color;
}
