.contact-form-wrapper{
  display: flex;
  justify-content: center;
  width:100%;
  background-color: #F1F1F1;
}

.contact-form-card-wrapper{
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  width: 80%;
  justify-content: center;
  margin-top: 5em;
  //padding: 2em 1em 2em 1em;
  column-gap: 4em;
}

.contact-form-card{
  display: grid;
  width: 100%;
  grid-template-rows: repeat(5, 50px);
  justify-self: end;
  //padding: 2;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2em;
  grid-template-areas: "name lastname"
  "email email"
  "adress adress"
  "postal place"
  "mobile mobile";
}

#contact-name{
  grid-area: name;
  width:100%;
}
#contact-lastname{
  grid-area: lastname;
}

#contact-email{
  grid-area: email;
}

#contact-adress{
  grid-area: adress;
  width:100%;
}
#contact-postal{
  grid-area: postal;
}

#contact-place{
  grid-area: place;
}


#contact-mobile{
  grid-area: mobile;
}

//.contact-textbox{
//  height:30px;
//  border: none;
//}

input[type=text] {
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  width:100%;
}

.contact-form-information-wrapper{
  display: grid;
  grid-template-rows: 1fr 3fr;
  background-color: #262787;
  padding-left: 2em;
  justify-content: center;

}

.contact-form-information{
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  color:white;
  grid-template-columns: 0.15fr 1fr;
  grid-template-rows: repeat(4, 40px);
  align-items: center;

}
