.not-found-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 200px;
}

.not-found-image {
  width: 30%;
  height: auto;
}

.not-found-wrapper > h2 {
  text-align: center;
  padding-bottom: 50px;
}
