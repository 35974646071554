.nav-wrapper {
  width: 100%;
  height: 80px;
  background: rgb(37, 37, 134);
  background: -moz-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  background: -webkit-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  background: -o-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  background: -ms-linear-gradient(left, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  background: linear-gradient(to right, rgb(37, 37, 134) 0%, rgb(107, 196, 222) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#53bcb3', endColorstr='#3f92c2', GradientType=1);
  display: grid;
  grid-template-columns: 0.25fr 2fr;
  color: white;
  z-index: 10000;
  text-decoration: none;
  list-style: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
}

//Gives the body some padding to prevent jerky movement of header
.sticky + #body {
  padding-top: 80px;
}

.nav-logo-wrapper {
  display: flex;
  align-items: center;
}

.nav-logo {
  width: 140px;
  min-width: 100px;
  margin-left: 2em;
}

.nav-menu-wrapper {
  display: flex;
  height:100%;
  align-items: center;
  justify-items: center;
  padding: 0 3em 0 0em;
}

.nav-menu-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
}

.nav-menu-wrapper li {
  float:left;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  color: inherit;
  height:100%;
}

.nav-menu-wrapper a{
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 0 16px;
  color:white;
  height:100%;
}

.nav-menu-wrapper a:hover{
  background-color: rgba(0,0,0,0.2);
}

.nav-menu-wrapper h4{
  color:inherit;
  font-size: 16px;
}

.active-nav{
  background-color: rgba(0,0,0,0.2);
  cursor: pointer;
}

//Styling of the dropdown content
.hidden-nav-content{
  flex-direction: column;
  top:100%;
  position: absolute;
  background: #6bc4de;
  min-width: 300px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  left:0
}

.hidden-nav-content > a{
  padding: 20px 16px 20px 16px;
  font-size: 12px;
}


.hidden-nav-content > a:hover{
  background-color: rgba(0,0,0,0.2);
}

//ID of the contents which are hidden

#home-content{
  display: none;
}

#home-nav:hover #home-content{
  display: flex;
}

#freelance-content{
  display: none;
}

#freelance-nav:hover #freelance-content{
  display: flex;
}

#diensten-content{
  display: none;
}

#diensten-nav:hover #diensten-content{
  display: flex;
}

#employers-content{
  display: none;
}

#employers-nav:hover #employers-content{
  display: flex;
}

#partners-content{
  display: none;
}

#partners-nav:hover #partners-content{
  display: flex;
}

#tech-community-content{
  display: none;
}

#tech-community-nav:hover #tech-community-content{
  display: flex;
}

#about-content{
   display: none;
 }

#about-nav:hover #about-content{
  display: flex;
}

.mobile-nav{
  display:none;
}

.nav-languages-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;
  white-space: normal;
  height:100%;
  padding:0 10px;
}

.nav-flag-div {
  display: flex;
}
.nav-flag-div > img{
  width:40px;
  height:40px;
}
.nav-languages-wrapper:hover {
  cursor: pointer;
}

.nav-languages-content {
  flex-direction: column;
  display: none;
  top:100%;
  position: absolute;
  background: #6bc4de;
  min-width: 150px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  right:0;
}

.nav-languages {
  width: 50px;
  max-width: 80px;
  display: flex;
  padding: 1em 0 1em 1em;;
}


.nav-languages-wrapper:hover .nav-languages-content {
  display: flex;
}

#english:hover {
  background-color: rgba(0,0,0,0.2);
  cursor: pointer;
}

#dutch:hover {
  background-color: rgba(0,0,0,0.2);
  cursor: pointer;
}

.nav-burger {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .nav-languages-wrapper {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .pc-nav{
    display: none;
  }

  .mobile-nav{
    display: flex;
  }
  .nav-burger {
    display: inline-block;
    padding: 0 0 0 1em;
  }

  .nav-menu-wrapper {
    justify-content: flex-end;
    padding: 0 2em 0 0;
  }

  .nav-languages-wrapper {
    margin-right: 2em;
    width:0;
  }

  .nav-menu-content {
    flex-direction: column;
    justify-content: flex-start;
    height: 0;
    padding: 0;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 80px;
    background-color: #6bc4de;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
    font-size: 30px;
  }

  .nav-menu-wrapper li {
    display: flex;
    height:auto;
    float:none;
    align-items: center;
    margin: 64px 0 0 0;
  }

  .nav-menu-wrapper a{
    padding: 0;
    height:auto;
  }

  .nav-menu-wrapper h4{
    padding: 0;
    height:auto;
    font-size: 32px;
  }

  .nav-menu-wrapper a:hover{
    background-color: rgba(0,0,0,0);
  }

  .active-nav{
    background-color: rgba(0,0,0,0);
    cursor: pointer;
  }

  .active-nav > h4{
    color:black;
  }

  .nav-flag-div > img{
    width:35px;
    height:35px;
  }

  #freelance-nav:hover #freelance-content{
    display: none;
  }

  #diensten-nav:hover #diensten-content{
    display: none;
  }

  #employers-nav:hover #employers-content{
    display: none;
  }

  #partners-nav:hover #partners-content{
    display: none;
  }

  #tech-community-nav:hover #tech-community-content{
    display: none;
  }

  #about-nav:hover #about-content{
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .nav-menu-wrapper h4{
    font-size: 24px;
  }

  .nav-menu-wrapper {
    padding: 0 1em 0 0;
  }

  .nav-menu-wrapper li {
    margin: 32px 0 0 0;
  }
  .nav-flag-div > img {
    margin-right:20px;
    width:30px;
    height:30px;
  }
}

@media only screen and (max-width: 400px) {
  .nav-menu-wrapper h4{
    font-size: 22px;
  }

  .nav-flag-div > img {
    width: 25px;
    height: 25px;
    margin-right:20px;
  }

  //Makes hamburger smaller
  .hamburger-inner::before, .hamburger-inner::after .hamburger-inner,
  .hamburger-inner::before, .hamburger-inner::after {
    width: 25px !important;
  }

  .nav-logo {
    width: 100px;
    min-width: 80px;
    margin-left: 1.5em;
  }

}

@media only screen and (max-width: 300px) {
  .nav-logo {
    width: 80px;
    min-width: 60px;
    margin-left: 0.3em;
  }
}
