.read-more-page-item {
  margin-top: 50px;
  padding: 32px 0  64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width:50%;

  .read-more-text {
    text-align: justify;
    padding-bottom: 20px;
  }

  .read-more {
    color: $blue_text_color;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
  }

  .read-more-title {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5em;
    justify-content: center;

    .title-image {
      height: 100%;
    }

    h2 {
      margin: 0 0;
      font-size: 34px;
      color: $blue_text_color;
    }
  }

  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 26px;
    }
    .read-more-text {
      text-align: left;
      padding: 20px 20px;
      width:100%;
    }

  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 22px;
    }
  }

}

@media screen and (max-width: 1200px) {
  .read-more-page-item {
    width: 90%;
  }


}
