.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;

    .title-view {
        max-width: 1000px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 50px 0;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }

        .title-image-wrapper {
            margin-right: 20px;
            max-height: 500px;
            height: 42vw;
            position: relative;

            @media screen and (max-width: 1000px) {
                height: 70vw;
            }

            .background-image {
                position: absolute;
                z-index: -1;
            }

            .foreground-image {
                position: absolute;
            }

            .title-image-1 {
                right: 27%;
                height: 140%;
                top: -30%;
            }

            .title-image-2 {
                right: 10%;
                height: 10%;
                top: 97%;
            }

            .title-image-3 {
                left: -11%;
                height: 20%;
                top: 40%;
            }

            .title-image {
                height: 100%;
            }
        }

        .title-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 45vw;

            @media screen and (max-width: 1000px) {
                width: 90vw;
                margin-top: 10vw;
            }

            .title {
                justify-content: flex-start;
                align-items: flex-end;
                display: flex;
                flex-direction: row;
                width: 100%;

                @media screen and (max-width: 1000px) {
                    justify-content: center;
                    width: 100%;
                    align-items: center;
                }

                .title-text {
                    max-width: 550px;
                    font-size: 30px;
                    font-weight: bold;
                    color: $blue_text_color;
                    line-height: 100%;
                    margin-right: 20px;
                    margin-bottom: 26px;

                    @media screen and (max-width: 1000px) {
                        text-align: center;
                        width: auto;
                        line-height: 100%;
                        padding: 0 0 16px 0;
                        margin: 20px 0 0 0;

                    }

                    @media screen and (max-width: 500px) {
                        //font-size: 10vw;
                        width: 65%;
                    }
                }

                .title-symbol {
                    height: 75px;
                    margin-bottom: 20px;

                    @media screen and (max-width: 1000px) {
                        height: 9vw;
                        margin: 0 0 0 10px;

                    }

                    @media screen and (max-width: 500px) {
                    }
                }
            }
            .subtitle {
                max-width: 500px;
                color: $grey_text_color;
                text-align: justify;
                font-size: 18px!important;
                line-height: 25px;
            }
        }
    }

    .items-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .side-decoration {
            position: absolute;
            top: 0;

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        .decoration-left {
            left: 4%;
            height: 95%;
        }

        .decoration-right {
            right: 4%;
            height: 98%;
        }

        .items {
            width: 100%;
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
}
