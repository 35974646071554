.general-button {
  background-color: #29316e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  p {
    padding: 10px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
}

a {
  text-decoration: none;
}

.general-button:hover {
  cursor: pointer;
  background-color: #6BC4DE;

}
