
.event-preview-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 0 15vh 0;
  justify-content: center;
  align-items: center;
}

.title > h2 {
  margin-bottom: 1em;
  color: #0B298D;
}

.event-preview-card {
  flex-direction: column;
  width: 35%;
  display: flex;
  align-items: center;
  padding: 15vh 0 0 0;
  margin: 0 0 10vh 0;
  position: relative;
}

.box {
  position: relative;
  display: flex;
  width: 300px;
  height: 300px;
  overflow: hidden;
  background: no-repeat center;
  background-size: cover;
  margin-top: 10px;
  border: solid #6BC4DE 3px;
}

.event-text {
  width: 60%;
  padding: 20px 0 10px 0;
  text-align: justify;
}

// Styling of the clicked event pagr
.event-wrapper{
  display: flex;
  height:100%;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
}

.h3-title{
  color: #0B298D;
  width: 60%;
  margin-top:20px;
  text-align: center;
  font-size:24px ;
}

.event-read-more-text {
  text-align: justify;
  max-width:1000px;
}

.event-read-more-text > p {
  padding: 20px 0 20px 0;
}


@media only screen and (max-width: 1350px) {

  .event-preview-wrapper {
    flex-direction: column;
    padding: 16px 0 32px 0;

  }

  .event-preview-card {
    width: 80%;
  }

  .box {
    width: 250px;
    height: 250px;
  }

  .h3-title{
    color: #0B298D;
    width: 400px;
  }

  .event-text {
    width: 400px;
  }
  .event-read-more-text {
    width:1000px;
  }
}

@media only screen and (max-width: 1000px) {

  .event-read-more-text {
    width:90vw;
  }

  .event-read-more-text > p {
    padding-top: 0;
  }
}

@media only screen and (max-width: 600px) {

  .title > h2 {
    margin-bottom: 0;
  }

  .event-preview-card {
    width: 90%;
  }

  .event-text {
    width: 90%;
  }

  .box {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 300px) {
  .box {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 200px) {
  .box {
    width: 100px;
    height: 100px;
  }
}



//.event-preview-wrapper {
//  display: flex;
//  width: 100%;
//  flex-direction: row;
//  flex-wrap: wrap;
//  padding: 16px 0 15vh 0;
//  justify-content: center;
//  align-items: center;
//}
//
//.title > h2 {
//  margin-bottom: 1em;
//  font-size: 40px;
//  color: #0B298D;
//}
//
//.event-preview-card {
//  flex-direction: column;
//  width:20%;
//  display: flex;
//  align-items: flex-start;
//  margin: 15vh 80px 0 80px;
//  position: relative;
//  -webkit-box-shadow: 0px 2px 5px 0px rgba(125,125,125,1);
//  -moz-box-shadow: 0px 2px 5px 0px rgba(125,125,125,1);
//  box-shadow: 0px 2px 5px 0px rgba(125,125,125,1);
//}
//
//.box {
//  position: relative;
//  display: flex;
//  width: 100%;
//  height: 300px;
//  overflow: hidden;
//  background: no-repeat center;
//  background-size: cover;
//  //border: solid #6BC4DE 3px;
//}
//
//.event-text-wrapper{
//  width: 100%;
//  padding: 20px 20px 10px 20px;
//  text-align:left;
//}
//
//.event-text-wrapper > p{
//  font-size: 14px;
//}
//
//.event-title{
//  font-size: 18px !important;
//}
//
//.event-date{
//  font-size:12px;
//}
//
//
//@media only screen and (max-width: 1200px) {
//
//  .event-preview-wrapper {
//    flex-direction: column;
//    padding: 16px 0 32px 0;
//
//  }
//
//  .event-preview-card {
//    width: 80%;
//  }
//
//  .box {
//    width: 250px;
//    height: 250px;
//  }
//
//  .event-text {
//    width: 400px;
//  }
//}
//
//@media only screen and (max-width: 600px) {
//
//  .title > h2 {
//    font-size: 30px;
//    margin-bottom: 0;
//  }
//
//  .event-preview-card {
//    width: 90%;
//  }
//
//  .event-text {
//    width: 90%;
//  }
//
//  .box {
//    width: 200px;
//    height: 200px;
//  }
//}
//
//@media only screen and (max-width: 300px) {
//  .box {
//    width: 150px;
//    height: 150px;
//  }
//}
//
//@media only screen and (max-width: 200px) {
//  .box {
//    width: 100px;
//    height: 100px;
//  }
//}
