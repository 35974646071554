$border-blue: #c1dfe8;
$input-blue: #eff2fc;

.contact-form {
    width: 95%;
    max-width: 500px ;
    margin-bottom: 30px;

    .title {
        border-color: $border-blue;
        border-bottom-style: solid;
        border-bottom-width: 3px;
        display: table;
        padding: 0 20px;
        font-size: 25px;
        color: $blue_text_color;
    }

    form {
        border-color: $border-blue;
        border-left-style: solid;
        border-left-width: 3px;
        padding-top: 30px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        .field {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            label {
                font-size: 15px;
                color: $blue_text_color;
            }

            .ui {
                input {
                    font-family: -apple-system, system-ui, BlinkMacSystemFont, "serif" !important;
                    font-size: 18px;
                    height: 30px;
                    width: 200px;
                    background-color: $input-blue;
                    border: none;
                }
            }

            textarea {
                font-family: -apple-system, system-ui, BlinkMacSystemFont, "serif" !important;
                font-size: 18px;
                width: 200px;
                height: 90px;
                background-color: $input-blue;
                border: none;
            }

        }

        .big-field {
            align-items: flex-start;
        }
    }
    .submit-button {
        color: white;
        padding: 10px;
        display: flex;
        align-self: center;
    }

    @media screen and (max-width: 600px) {
        .title {
            border-color: $border-blue;
            border-bottom-style: solid;
            border-bottom-width: 3px;
            display: table;
            padding: 0 20px;
            font-size: 25px;
            color: $blue_text_color;
        }

        form {
            display: flex;
            flex-direction: column;

            .field {
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                .ui {
                    width:100% !important;
                    input {
                        font-size: 18px;
                        height: 30px;
                        width: 100%;
                        background-color: $input-blue;
                        border: none;
                    }
                }

                textarea{
                    width:100%;
                }
            }

        }
    }

    @media screen and (max-width: 300px) {

        form {
            .field {
                .ui {
                    input {
                        width: 90%;
                    }
                }
            }
        }
    }
}

